<template>
  <div class="constrain part">
    <div class="row-12">
      <div v-for="person in personen" :key="person.id" class="md:col-8 md:offset-2 mb-16">
        <h3 v-if="person.ueberschrift" class="team-heading mb-8" v-html="person.ueberschrift" />
        <div class="person-container row-12 bg-white">
          <div class="md:col-4">
            <img
              v-if="person.portrait"
              class="person-portrait"
              :src="person.portrait.large"
              :alt="person.portrait.alt"
            >
          </div>
          <div class="md:col-8 p-16">
            <div class="row-12">
              <div class="md:col-12 mb-8">
                <h3 class="mb-4" v-html="person.name" />
                <p v-if="person.firmaposition" v-html="person.firmaposition" />
              </div>
              <div v-if="person.adresse" class="md:col-6" v-html="person.adresse" />
              <div class="md:col-6">
                <div>
                  <a v-if="person.telefon" :href="'tel:' + person.telefon">Tel: {{ person.telefon }}</a>
                </div>
                <div>
                  <a v-if="person.fax" :href="'tel:' + person.fax">Fax: {{ person.fax }}</a>
                </div>
                <div>
                  <a v-if="person.email" :href="'mailto:' + person.email">{{ person.email }}</a>
                </div>
                <div>
                  <a v-if="person.emailalt" :href="'mailto:' + person.emailalt">{{ person.emailalt }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    personen() {
      return this.payload.personen;
    },
  },
  mounted() {
    console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>

:deep(.person-container) {
  p, a {
    color: var(--color-navy);
  }
  a[href^="mailto"] {
    color: var(--color-blue);
    text-decoration: underline;
  }
}

.person-portrait {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
